import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
	AiFillGithub,
	AiOutlineTwitter,
	AiFillInstagram,
} from 'react-icons/ai';
import { FaLinkedinIn } from 'react-icons/fa';
//https://www.youtube.com/watch?v=_zshr7fswRQ&t=409s

function Footer() {
	let date = new Date();
	let year = date.getFullYear();
	return (
		<Container
			fluid
			className='footer'
		>
			<Row>
				<Col
					md='4'
					className='footer-copywright'
				>
					<h3>Ralph Smith</h3>
				</Col>
				<Col
					md='4'
					className='footer-copywright'
				>
					<h3>Copyright © {year}</h3>
				</Col>
				<Col
					md='4'
					className='footer-body'
				>
					<ul className='footer-icons'>
						<li className='social-icons'>
							<a
								href='https://github.com/rlondon3'
								style={{ color: 'white' }}
								target='_blank'
								rel='noopener noreferrer'
							>
								<AiFillGithub />
							</a>
						</li>
						<li className='social-icons'>
							<a
								href='https://twitter.com/codesmithr'
								style={{ color: 'white' }}
								target='_blank'
								rel='noopener noreferrer'
							>
								<AiOutlineTwitter />
							</a>
						</li>
						<li className='social-icons'>
							<a
								href='https://www.linkedin.com/in/raphael-smith-b91a65206/'
								style={{ color: 'white' }}
								target='_blank'
								rel='noopener noreferrer'
							>
								<FaLinkedinIn />
							</a>
						</li>
						<li className='social-icons'>
							<a
								href='https://www.instagram.com/ralphiedidit'
								style={{ color: 'white' }}
								target='_blank'
								rel='noopener noreferrer'
							>
								<AiFillInstagram />
							</a>
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
}

export default Footer;
